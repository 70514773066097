<template lang="pug">
.flex.flex-col.overflow-y-auto.notify-control.w-full
  .flex
    skif-checkbox.ml-4(
      :disabled='isAllowedCreate',
      v-model='notification.weeks_control'
    )
    p.ml-6.font-semibold.text-sm.font-SourceSansPro.transition-color.duration-300(
      :class='notification.weeks_control ? "text-darkblue" : "text-annotationColor"'
    ) {{ $t("notifications.temporaryValueMonitoring") }}
  .flex.border-b.border-reallylightblue.mt-6.justify-between.py-3
    .flex.items-center
      skif-checkbox.ml-4(
        :disabled='isAllowedCreate',
        v-model='notification.wc_active_1'
      )
      p.ml-6.font-bold.text-darkblue.text-sm.transition-color.duration-300(
        :class='notification.wc_active_1 ? "text-darkblue" : "text-annotationColor"'
      ) {{ $t("notifications.monday") }}
    .flex.items-center
      p.font-bold.text-sm.self-center.mr-4.transition-color.duration-300(
        :class='notification.wc_active_1 ? "text-darkblue" : "text-annotationColor"'
      ) {{ $t("notifications.from") }}
      el-time-picker(
        :disabled='isAllowedCreate',
        format='HH:mm',
        value-format='HH:mm',
        :clearable='false',
        v-model='notification.wc_from_1'
      )
      p.font-bold.text-sm.self-center.mx-4.transition-color.duration-300(
        :class='notification.wc_active_1 ? "text-darkblue" : "text-annotationColor"'
      ) {{ $t("notifications.to") }}
      el-time-picker(
        :disabled='isAllowedCreate',
        format='HH:mm',
        value-format='HH:mm',
        :clearable='false',
        v-model='notification.wc_to_1'
      )
  .flex.border-b.border-reallylightblue.py-3.justify-between
    .flex.items-center
      skif-checkbox.ml-4(
        :disabled='isAllowedCreate',
        v-model='notification.wc_active_2'
      )
      p.ml-6.font-bold.text-darkblue.text-sm.transition-color.duration-300(
        :class='notification.wc_active_2 ? "text-darkblue" : "text-annotationColor"'
      ) {{ $t("notifications.tuesday") }}
    .flex.items-center
      p.font-bold.text-sm.self-center.mr-4.transition-color.duration-300(
        :class='notification.wc_active_2 ? "text-darkblue" : "text-annotationColor"'
      ) {{ $t("notifications.from") }}
      el-time-picker(
        :disabled='isAllowedCreate',
        format='HH:mm',
        value-format='HH:mm',
        :clearable='false',
        v-model='notification.wc_from_2'
      )
      p.font-bold.text-sm.self-center.mx-4.transition-color.duration-300(
        :class='notification.wc_active_2 ? "text-darkblue" : "text-annotationColor"'
      ) {{ $t("notifications.to") }}
      el-time-picker(
        :disabled='isAllowedCreate',
        format='HH:mm',
        value-format='HH:mm',
        :clearable='false',
        v-model='notification.wc_to_2'
      )
  .flex.border-b.border-reallylightblue.py-3.justify-between
    .flex.items-center
      skif-checkbox.ml-4(
        :disabled='isAllowedCreate',
        v-model='notification.wc_active_3'
      )
      p.ml-6.font-bold.text-darkblue.text-sm.transition-color.duration-300(
        :class='notification.wc_active_3 ? "text-darkblue" : "text-annotationColor"'
      ) {{ $t("notifications.wednesday") }}
    .flex.items-center
      p.font-bold.text-sm.self-center.mr-4.transition-color.duration-300(
        :class='notification.wc_active_3 ? "text-darkblue" : "text-annotationColor"'
      ) {{ $t("notifications.from") }}
      el-time-picker(
        :disabled='isAllowedCreate',
        format='HH:mm',
        value-format='HH:mm',
        :clearable='false',
        v-model='notification.wc_from_3'
      )
      p.font-bold.text-sm.self-center.mx-4.transition-color.duration-300(
        :class='notification.wc_active_3 ? "text-darkblue" : "text-annotationColor"'
      ) {{ $t("notifications.to") }}
      el-time-picker(
        :disabled='isAllowedCreate',
        format='HH:mm',
        value-format='HH:mm',
        :clearable='false',
        v-model='notification.wc_to_3'
      )
  .flex.border-b.border-reallylightblue.py-3.justify-between
    .flex.items-center
      skif-checkbox.ml-4(
        :disabled='isAllowedCreate',
        v-model='notification.wc_active_4'
      )
      p.ml-6.font-bold.text-darkblue.text-sm.transition-color.duration-300(
        :class='notification.wc_active_4 ? "text-darkblue" : "text-annotationColor"'
      ) {{ $t("notifications.thursday") }}
    .flex.items-center
      p.font-bold.text-sm.self-center.mr-4.transition-color.duration-300(
        :class='notification.wc_active_4 ? "text-darkblue" : "text-annotationColor"'
      ) {{ $t("notifications.from") }}
      el-time-picker(
        :disabled='isAllowedCreate',
        format='HH:mm',
        value-format='HH:mm',
        :clearable='false',
        v-model='notification.wc_from_4'
      )
      p.font-bold.text-sm.self-center.mx-4.transition-color.duration-300(
        :class='notification.wc_active_4 ? "text-darkblue" : "text-annotationColor"'
      ) {{ $t("notifications.to") }}
      el-time-picker(
        :disabled='isAllowedCreate',
        format='HH:mm',
        value-format='HH:mm',
        :clearable='false',
        v-model='notification.wc_to_4'
      )
  .flex.border-b.border-reallylightblue.py-3.justify-between
    .flex.items-center
      skif-checkbox.ml-4(
        :disabled='isAllowedCreate',
        v-model='notification.wc_active_5'
      )
      p.ml-6.font-bold.text-darkblue.text-sm.transition-color.duration-300(
        :class='notification.wc_active_5 ? "text-darkblue" : "text-annotationColor"'
      ) {{ $t("notifications.friday") }}
    .flex.mb-1
      p.font-bold.text-sm.self-center.mr-4.transition-color.duration-300(
        :class='notification.wc_active_5 ? "text-darkblue" : "text-annotationColor"'
      ) {{ $t("notifications.from") }}
      el-time-picker(
        :disabled='isAllowedCreate',
        format='HH:mm',
        value-format='HH:mm',
        :clearable='false',
        v-model='notification.wc_from_5'
      )
      p.font-bold.text-sm.self-center.mx-4.transition-color.duration-300(
        :class='notification.wc_active_5 ? "text-darkblue" : "text-annotationColor"'
      ) {{ $t("notifications.to") }}
      el-time-picker(
        :disabled='isAllowedCreate',
        format='HH:mm',
        value-format='HH:mm',
        :clearable='false',
        v-model='notification.wc_to_5'
      )
  .flex.border-b.border-reallylightblue.py-3.justify-between
    .flex.items-center
      skif-checkbox.ml-4(
        :disabled='isAllowedCreate',
        v-model='notification.wc_active_6'
      )
      p.ml-6.font-bold.text-darkblue.text-sm.transition-color.duration-300(
        :class='notification.wc_active_6 ? "text-darkblue" : "text-annotationColor"'
      ) {{ $t("notifications.saturday") }}
    .flex.items-center
      p.font-bold.text-sm.self-center.mr-4.transition-color.duration-300(
        :class='notification.wc_active_6 ? "text-darkblue" : "text-annotationColor"'
      ) {{ $t("notifications.from") }}
      el-time-picker(
        :disabled='isAllowedCreate',
        format='HH:mm',
        value-format='HH:mm',
        :clearable='false',
        v-model='notification.wc_from_6'
      )
      p.font-bold.text-sm.self-center.mx-4.transition-color.duration-300(
        :class='notification.wc_active_6 ? "text-darkblue" : "text-annotationColor"'
      ) {{ $t("notifications.to") }}
      el-time-picker(
        :disabled='isAllowedCreate',
        format='HH:mm',
        value-format='HH:mm',
        :clearable='false',
        v-model='notification.wc_to_6'
      )
  .flex.border-b.border-reallylightblue.py-3.justify-between
    .flex.items-center
      skif-checkbox.ml-4(
        :disabled='isAllowedCreate',
        v-model='notification.wc_active_7'
      )
      p.ml-6.font-bold.text-darkblue.text-sm.transition-color.duration-300(
        :class='notification.wc_active_7 ? "text-darkblue" : "text-annotationColor"'
      ) {{ $t("notifications.sunday") }}
    .flex.items-center
      p.font-bold.text-sm.self-center.mr-4.transition-color.duration-300(
        :class='notification.wc_active_7 ? "text-darkblue" : "text-annotationColor"'
      ) {{ $t("notifications.from") }}
      el-time-picker(
        :disabled='isAllowedCreate',
        format='HH:mm',
        value-format='HH:mm',
        :clearable='false',
        v-model='notification.wc_from_7'
      )
      p.font-bold.text-sm.self-center.mx-4.transition-color.duration-300(
        :class='notification.wc_active_7 ? "text-darkblue" : "text-annotationColor"'
      ) {{ $t("notifications.to") }}
      el-time-picker(
        :disabled='isAllowedCreate',
        format='HH:mm',
        value-format='HH:mm',
        :clearable='false',
        v-model='notification.wc_to_7'
      )
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['notification'],
  computed: {
    ...mapGetters('login', ['role', 'disabledRole', 'notRole']),
    isAllowedCreate() {
      return this.disabledRole && this.role !== 'OPERATOR'
    }
  }
}
</script>

<style lang="stylus">
.notify-control {
  .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 5rem;

    .el-input__icon {
      line-height: 2.5rem;
    }

    .el-input--suffix, .el-input__inner {
      padding-right: 0px;
    }
  }
}
</style>
